.Schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Schedule h2 {
    font-size: 2.5em;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.Schedule img {
    width: 90%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
    overflow: hidden;
}