@import url("./theme.css");
.contact {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}

.contact h2 {
    font-size: var(--fs-2) !important;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact .box {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    /* background: var(--primary); */
    background: linear-gradient(45deg,#7555a3,#797979  );
    border-radius: 30px;
    padding: 10px;
    color: var(--secondary);
}


.contact .box .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-wrap: wrap;
}

.contact .box .left div {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: var(--fs-1);
}

.contact .box .left div p a {
    text-decoration: none;
    color: #ffffff;
}

.contact .box .rigth {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

iframe{
    width: 55%;
    border-radius: 30px;
}

/* mediaquires */

@media screen and (max-width: 768px) {
    .contact .box {
        flex-direction: column;
    }

    .contact .box .left {
        width: 100%;
    }

    .contact .box .rigth {
        width: 90%;
    }

    iframe{
        width: 90%;
    }
}