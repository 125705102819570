@import url("./theme.css");

.marquee-container {
    width: 100%;
    overflow: hidden;
    background: var(--primary);
    padding: 10px 0;
    margin: 10px 0;
    height: 60px;
    display: flex;
    align-items: center;
}

.marquee {
    display: flex;
    align-items: center;
    animation: marquee 25s linear infinite;
    white-space: nowrap;
}

.marquee a {
    height: 100%;
    margin-right: 50px;
    white-space: nowrap;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marquee a img {
    height: 50px;
    /* Set your desired height */
    margin-right: 8px;
    /* Space between the image and text */
}

.marquee a:hover {
    text-decoration: underline;
}

.marquee p {
    margin: 0 30px;
    padding: 0;
    font-size: clamp(16px, 2.5vw, 20px);
    color: white;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
      
        transform: translateX(-100%);
    }
}