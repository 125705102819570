@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu&display=swap');
@import url("./styles/theme.css");
* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}



/* scrollbar of color  linear-gradient(45deg, #9500ff, #630092, #9500ff); */

/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1e1c1c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  width: 5px;
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  cursor: pointer;
}

