@import url('./theme.css');

.footer {
    background: var(--primary);
    /* padding: 0.5rem; */
    width: 100%;
    height: 65px;
    text-align: center;
    color: var(--secondary);
}

.footer p {
    margin: "10px";
}

.footer p a {
    color: #fff;
    text-decoration: "none";
    padding: 0 10px;
    /* margin-top: "-5px"; */
}

/* make it responsive */
@media screen and (max-width:800px) {
    .footer {
        /* padding: 0.5rem; */
        height: 200px;
    }

    .footer p{
        display: flex;
        flex-direction: column;

    }
}