@import url("./theme.css");
.callforpapers {
    width: 100%;
    height: auto;
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* 
.callforpapers h2 {
    font-size: var(--fs-2) !important;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.callforpapers p {
    font-size: var(--fs-1);
    margin-bottom: 20px;
    text-align: justify;
    padding: 0 100px;
}

.callforpapers ul {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: justify;
    padding: 0 100px;
}

.callforpapers ul li {
    margin-bottom: 10px;
    font-size: var(--fs-1);
}

.callforpapers ul h4 {
    font-size: var(--fs-2);
    margin-bottom: 10px;
}

.callforpapers h3 {
    font-size: var(--fs-3) !important;
    margin-bottom: 10px;
    font-weight: 600;
    padding: 0 100px;
} */

.callforpapers img{
    width: 90%;
    height: 100%;
    border-radius: 30px;
    object-fit: contain;
    overflow: hidden;
    margin: 0 auto;
    border: 10px solid #694ea8;
}

/* mediquires */

@media screen and (max-width: 768px) {
    .callforpapers p {
        padding: 0 25px;
    }

    .callforpapers h2 {
        padding: 0 25px;
        font-size: 1.5em;
        text-align: justify;

        font-weight: 700;
    }

    .callforpapers ul {
        padding: 0 35px;
    }

    .callforpapers h3 {
        padding: 0 25px;
    }

    .callforpapers ul h4 {
        /* padding: 0 35px; */
        font-size: 1.2em;
    }
}