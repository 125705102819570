@import url("./theme.css");

.Committee {
    width: auto;
    padding: 20px;
    box-sizing: border-box;
}

.Committee h1 {
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    margin: 40px 0;
    margin-bottom: 100px;
    color: var(--primary-color, #694fa9);
    border-top: 10px solid var(--primary-color, #694fa9);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.Committee .committeeBox {
    /* background: #000; */
    margin-bottom: 50px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--primary-color, #694fa9);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.Committee .committeeBox h2 {
    font-size: var(--fs-2, 1.8em);
    margin-top: 20px;
    margin-bottom: 10px;
    text-decoration: underline;
    font-weight: bold;
    color: var(--primary-color, #694fa9);
    /* border-bottom: 5px solid var(--primary-color, #694fa9); */
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 5px; */
}

.Committee .committeeBox h3 {
    font-size: var(--fs-3, 1.5em);
    margin-bottom: 10px;
    font-weight: 500;
    color: black;
}

.Committee .committeeBox .committeeBoxMembers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin: 20px 0;
    /* background: #000; */
}

.Committee .committeeBox .committeeBoxMembers .committeeBoxMember {
    width: 280px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 15px;
    border-radius: 10px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* border: 1px solid var(--primary-color, #694fa9); */
}

.Committee .committeeBox .committeeBoxMembers .committeeBoxMember:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.Committee .committeeBox .committeeBoxMembers .committeeBoxMember img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 5px solid var(--primary-color, #694fa9);
    padding: 2px;
}
.Committee .committeeBoxList,
.Committee .committeeBoxList2 {
    width: 99%;
    margin: 30px auto;
    padding: 0 20px;
    text-align: left;
    box-sizing: border-box;
    border-bottom: 3px solid var(--primary-color, #694fa9);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.Committee .committeeBoxList2 .biglist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
}

.Committee .committeeBoxList h2,
.Committee .committeeBoxList2 h2 {
    font-size: var(--fs-2, 1.8em);
    margin-bottom: 20px;
    text-decoration: underline;
    font-weight: bold;
    color: var(--primary-color, #694fa9);
    text-align: center;
}

.Committee .committeeBoxList ul,
.Committee .committeeBoxList2 ul {
    list-style-type: circle;
    padding-left: 20px;
    margin: 10px 0;
}

.Committee .committeeBoxList ul li,
.Committee .committeeBoxList2 ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
    line-height: 1.5;
}

.Committee .twocolumn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* 4 columns on desktop */
.Committee .committeeBoxList2 ul {
    width: calc(25% - 20px);
    /* This makes each list take up 25% of the container width */
}

/* For smaller screens, make the layout 1 column */
@media screen and (max-width: 1024px) {
    .Committee .committeeBoxList2 ul {
        width: 100%;
        /* 100% width for tablets and small screens */
    }
}

@media screen and (max-width: 768px) {
    .Committee h1 {
        font-size: 2em;
    }

    .Committee .committeeBoxList ul li,
    .Committee .committeeBoxList2 ul li {
        font-size: 1em;
    }

    /* Adjust list items for tablets and smaller screens */
    .Committee .committeeBox .committeeBoxMembers .committeeBoxMember {
        width: 100%;
    }

    .Committee .committeeBoxList,
    .Committee .committeeBoxList2 {
        padding: 0 15px;
        /* border-bottom: 2px solid var(--primary-color, #694fa9); */
        /* background: #000; */
    }

    .Committee .twocolumn {
        flex-direction: column;
        /* Stack the columns on smaller screens */
    }

    .Committee .committeeBox h2 {
        font-size: 1.5em;
    }

    .Committee .committeeBoxList h2,
    .Committee .committeeBoxList2 h2 {
        font-size: 1.5em;
        border-bottom: 2px solid var(--primary-color);

    }

    .Committee .committeeBoxList ul li,
    .Committee .committeeBoxList2 ul li {
        font-size: 1em;
    }
}

@media screen and (max-width: 480px) {
    .Committee h1 {
        font-size: 1.8em;
        margin: 30px 0;
    }

    .Committee .committeeBox h2 {
        font-size: 1.2em;
    }

    .Committee .committeeBoxList ul li,
    .Committee .committeeBoxList2 ul li {
        font-size: 0.9em;
    }

    .Committee .committeeBox .committeeBoxMembers .committeeBoxMember img {
        width: 150px;
        height: 150px;
    }
}