@import url("./theme.css");
.about {
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: var(--fs-1);
}

/* .about h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
} */

.about .college {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    margin-top: 10px;
    padding: 25px;
}
.about .college p{
    font-size: var(--fs-1) !important;
}

.about h2 {
    font-size:var(--fs-2);
    font-weight: 600;
    color: #060006;
}

.about .left {
    width: 45%;
    height: auto;
    margin-top: 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    text-align: justify;
    font-size: var(--fs-1);

}

.about .right {
    /* font-size: 1.2rem; */
    font-size: var(--fs-1) !important;
    width: 45%;
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    text-align: justify;
    border-radius: 10px;
}

/* mediaquires */
@media (max-width: 768px) {
    .about .college {
        flex-direction: column;
    }

    .about .left {
        width: 90%;
    }

    .about .right {
        width: 90%;
    }

    .about .reverse {
        flex-direction: column-reverse;
    }
}